import React from 'react';
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Header from "../components/header/Header";
import Blog from "../components/blog/Blog";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";
import "../assets/scss/main.scss";
import ContactAndInfo from '../components/ContactAndInfo';

export default function Template({
  data,
}) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <>
      <Header />
      <div>
        <div className="scrollable">
          <div className="blog-grid">
            <div className="blog-img">
            <Img style={{ maxHeight: '400px',  objectFit: 'scale-down'  }} objectFit={'scale-down'} fluid={frontmatter.cover?.childImageSharp.fluid} />
            </div>
            {/* End blog-img */}
            <article className="article">
              <div className="article-title">
                <h2>
                  {frontmatter.title}
                </h2>
                <div className="media">
                  <div className="avatar">
                    <img src="/img/about/yohanna.png" alt="thumbnail" />
                  </div>
                  <div className="media-body">
                    <label style={{'color': 'black'}}>Yohanna</label>
                    <span>{frontmatter.date}</span>
                  </div>
                </div>
              </div>
              {/* End .article-title */}
              <div className="article-content" dangerouslySetInnerHTML={{ __html: html }}/>
              {/* End article content */}
            </article>
            {/* End Article */}

          </div>
        </div>
      </div>




            {/* Blog */}
            <section id="blog" className="section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Mes articles récents</h3>
              <p style={{'color':'hsl(50deg 60% 21%)'}} >
                Retrouvez ici toutes mes explications et mes conseils pratiques.
              </p>
            </div>
          </div>
          {/* End .row */}
          <Blog />
          <div className="text-center mt-5"><a className="px-btn px-btn-theme btn-sm" href="/blog"> voir plus </a></div>
        </div>
      </section>
      {/*  End Blog */}

            {/* Contact */}
            <section id="contact" className="section after-left-section">
        <ContactAndInfo />
      </section>
      {/* End Contact */}


      {/* Footer */}
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/*  End Footer */}
    </>
  );
}

export const pageQuery = graphql`
  query BlogPostByPath($title: String!) {
    markdownRemark(frontmatter: { title: { eq: $title } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        cover {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;